import { createApp } from 'vue'

import ElementPlus from 'element-plus';
// import icons from '@element-plus/icons-vue';
// import 'element-plus/lib/theme-chalk/index.css';
import './styles/index.scss'

import App from './App.vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/store'
import router from './routers/index';
import i18n from './i18n'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(VueAxios, axios)
app.use(i18n)
app.mount('#app')
// 添加这段代码支持热更新
if (import.meta.hot) {
    import.meta.hot.accept()
}
