// utils/lazyLoadComponent.js
import { defineAsyncComponent } from 'vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import ErrorComponent from '../components/ErrorComponent.vue';

export default function lazyLoadComponent(path) {
    return defineAsyncComponent({
        loader: () => import(path),
        loadingComponent: LoadingSpinner,
        errorComponent: ErrorComponent,
        onError(error, retry, fail, attempts) {
            if (error.message.match(/fetch failed/i) && attempts < 3) {
                retry();
            } else {
                fail();
            }
        },
    });
}
