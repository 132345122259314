export default {
    common: {
      next: '下一步',
      back: '返回',
      connect: '连接',
      cancel: '关闭',
      confirm: '提交'
    },
    main: {
        signOut: '退出',
        addGroup: '添加账户组',
        switchGroup: '切换账户组'
    },
    menu: {
        settings: '设置',
        overview: '概述',
        adAnalysis: '广告分析',
        action: '行为',
        goal: '目标',
        advanceAnalysis: '高级分析',
        campaignAnalysis: '活动分析',
        efficiencyAnalysis: '效率分析',
        dimensionTimeSeries: '维度时间序列',
        KPISelection: '关键绩效指标选择',
        GAClickMatching: 'Google 分析',
        adScoring: '广告评分',
        dailyReport: '每日报告',
        reports: '报表'
      },
      login: {
        tipFragment1: '欢迎来到',
        tipFragment2: 'Humanlytics Data Slinger',
        tipFragment3: '数字分析体验。',
        tip2Fragment1: '请使用与您的 Google Analytics/Facebook',
        tip2Fragment2: '广告平台相关联的 Google/Facebook 帐户在下方登录',
        tip3: '我同意',
        privacyPolicy: '用户隐私政策',
        SignButton: '使用谷歌登录'
      },
      step23: {
        companyName: '让我们先了解一下你的公司/客户。你的公司/客户叫什么名字？',
        timezone: '您希望贵公司的销售或分析平台统一在哪个时区？'
      },
      step31: {
        tip: '您的帐户组已在我们的数据库中创建。现在，让我们将您的所有分析平台与此帐户组集成。',
        tip2: '请选择您希望通过我们的平台分析的分析帐户（目前只有Google Analytics可用于测试版）',
      },
      connect: {
        tip: '现在，让我们将您的所有分析平台与此帐户组集成。',
        tip2: '请选择您希望通过我们的平台分析的分析账户（如果适用，建议使用所有三个平台）',
        diferrent: '登录其它账户',
        toConnect: '要继续使用您当前的',
        toConnect2: '帐户，请按“继续”。如果您希望使用其他帐户，请提供身份验证详细信息。',
        login: '登录',
        countinue: '继续',
        bindShare:'分享绑定链接'
      },
      analysis: {
        name: '名称',
        timezone: '时区',
        managerAccount: '管理账户',
        selected: '选择',
        created: '账户ID',
        accounts: '账户',
        properties: '网站',
        views: '数据视图',
        balance: '余额'
      }
  };
