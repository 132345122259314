import Cookie from 'js-cookie';

// 获取cookie
export const getCookie = (key = '') => Cookie.get(key);

// 设置cookie
export const setCookie = (key, data) => Cookie.set(key, data);

// 格式化平台名称，eg. 'facebookInstagram' => 'Facebook Instagram'
export const transformPlatformName = (name) => {
  if (!name) {
    return name;
  }

  const isAllCapital = /^[A-Z]+$/.test(name);
  if (isAllCapital) {
    return name;
  }

  const formatName = name.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })

  return formatName;
}