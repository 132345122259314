const goals = [
	{
		path: '/goals',
		component: () => import('../pages/main.vue'),
		meta: { role: [3] },
		children: [
			{ path: 'step1', component: () => import('../views/goals/step1.vue'), meta: { role: [3] } },
			{ path: 'step2', component: () => import('../views/goals/step2.vue'), meta: { role: [3] } },
			{ path: 'step3', component: () => import('../views/goals/step3.vue'), meta: { role: [3] } },
			{ path: 'list', component: () => import('../views/goals/list.vue'), meta: { role: [3] } },
			{ path: 'detail', component: () => import('../views/goals/detail.vue'), meta: { role: [3] } },
			{ path: 'split', component: () => import('../views/goals/split.vue'), meta: { role: [3] } },
		],
	},
];

export default goals;
