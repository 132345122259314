<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="login-left">
        <div>
          <el-icon class="success-icon"><Finished /></el-icon>
          <div class="error">Success</div>
        </div>
        <div class="welcome">
          <div>Installation is complete.</div>
          <div>Account Name: {{ name }}</div>
          <div>Account Group: {{ accountGroupId }}</div>
          <div>Shopify URL: {{ shop }}</div>
        </div>
      </div>
      <div class="login-right">
        <!-- <el-button
          type="primary" class="button"
        >
          Confirm
        </el-button> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { Finished } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'ShopifyBindSuccess',
  setup() {
    const route = useRoute();
    const accountGroupId = route.query.accountGroupId;
    const shop: any = route.query.shop;
    const name: any = route.query.name;

    return {
      accountGroupId,
      shop,
      name
    };
  }
});
</script>

<style lang="less" scoped>
.login-wrapper {
  position: relative;
  min-width: 900px;
  min-height: 400px;
  text-align: center;
  z-index: 100;
  width: 100%;
  height: 100%;
  //background-image: url("../../assets/onboarding/lets-start.png");
  object-fit: cover;

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:  rgba(0,0,0,0.24);
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:   rgba(0,0,0,0.24);
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    rgba(0,0,0,0.24);
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:   rgba(0,0,0,0.24);
  }
  .login-box {
    width: 900px;
    height: 400px;
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.20);
    position: absolute;
    border: solid 1px #e7ebee;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -450px;
    .welcome {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.56px;
      text-align: center;
      color: #6c6c6c;
      width: 660px;
      margin:0 auto;
    }
  }
  .success-icon {
    color: #00b062;
    font-size: 58px;
    margin-top: 40px;
  }
  .error {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.69px;
    color: #6c6c6c;
    margin-bottom: 30px;
  }
  .login-right {
    border-top: solid 1px #e7ebee;
    margin-top: 40px
  }
  .button {
    border-radius: 20.5px;
    background-color: #00b062;
    width: 175.4px;
    height: 56.2px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #fff;
    margin-top: 20px;
  }
}
</style>
