<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="login-left">
        <div>
          <el-icon class="warn-icon"><Warning /></el-icon>
          <div class="error">Error</div>
        </div>
        <div class="welcome">
          <div>Installation is abnormal.</div>
           Please check whether the shop address filled in is consistent with the login address</div>
<!--        <div class="desc"></div>-->
      </div>
      <div class="login-right">
          <el-button
            type="primary" class="button" :loading="loading"
            @click="reInstall"
          >
            Install Again
          </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElIcon } from 'element-plus';
import { getTestUserToken, shopifyQuickLogin } from '../../api/user';
import store from '../../store/store';
import { postPlatform } from '../../api/onboarding';
import {Warning} from '@element-plus/icons-vue'
export default {
  data() {
    return {
      loading: false, // 登录loading
      username: '',
      password: '',
      timer: 0,
      token: '637dd6c457660a376a35a5fb'
    };
  },
  components: {
    ElIcon,
    Warning
  },
  watch: {},
  methods: {
    reInstall() {
      window.location.href = 'https://apps.shopify.com/humanlytics-dataslinger-public'
    },
    async submit() {
      const account = {
        username: 'test1111',
        password: 'test1111',
      }

      let msg = '';

      if (!this.username) {
        msg = 'Please enter your username';
      } else if (!this.password) {
        msg = 'Please enter your password';
      } else if (this.password !== account.password || this.username !== account.username) {
        msg = 'Please check your username or password';
      } else if (!this.token) {
        msg = 'token error';
      }

      if (msg) {
        ElMessage.error({
          message: msg,
          type: 'error'
        });
      } else {
        this.login(this.token)
      }

    },
    async quickLogon() {
      
    },
    async login(accountGroupId) {
      const tokenResult = await getTestUserToken({accountGroupId});
        if (!tokenResult) {
          ElMessage.error({
            message: 'token error!',
            type: 'error'
          });
          return;
        }
        store.commit('setToken', tokenResult.token);
        const accessToken = this.$route.query.accessToken
        const profileURL = this.$route.query.shop
        const params = {
          accountgroupId: '637dd6c457660a376a35a5fb',
          userId: '63bf6f4ad36928fd8fbea726',
          token:{
            accessToken
          },
          profile: {
            profileURL
          },
          platform: 'shopifyLogin',
        }
        // debugger;
        const result = await postPlatform(params)
        this.$router.push({
          name: 'callback',
          query: {
            token: tokenResult.token,
          }
        });
    }
  },
  async mounted() {
    const accessToken = this.$route.query.accessToken
    const profileURL = this.$route.query.shop
    
    const result = await shopifyQuickLogin({accessToken: accessToken})
    if (result.accountGroupId) {
      this.login(result.accountGroupId)
    }
  },

};
</script>

<style lang="less" scoped>
  .login-wrapper {
    position: relative;
    min-width: 900px;
    min-height: 400px;
    text-align: center;
    z-index: 100;
    width: 100%;
    height: 100%;
    //background-image: url("../../assets/onboarding/lets-start.png");
    object-fit: cover;

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:  rgba(0,0,0,0.24);
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color:   rgba(0,0,0,0.24);
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:    rgba(0,0,0,0.24);
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:   rgba(0,0,0,0.24);
    }
    .login-box {
      width: 900px;
      height: 400px;
      box-shadow: 0 8px 20px 0 rgba(0,0,0,0.20);
      position: absolute;
      border: solid 1px #e7ebee;
      top: 50%;
      left: 50%;
      margin-top: -250px;
      margin-left: -450px;
      .welcome {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.56px;
        text-align: center;
        color: #6c6c6c;
        width: 660px;
        margin:0 auto;
      }
    }
    .warn-icon {
      color: #ff1414;
      font-size: 58px;
      margin-top: 60px;
    }
    .error {
      font-family: Montserrat;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.69px;
      color: #6c6c6c;
      margin-bottom: 30px;
    }
    .login-right {
      border-top: solid 1px #e7ebee;
      margin-top: 40px
    }
    .button {
      border-radius: 20.5px;
      background-color: #00b062;
      width: 175.4px;
      height: 56.2px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #fff;
      margin-top: 20px;
    }
  }

</style>
