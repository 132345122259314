import Axios from 'axios';
import qs from 'qs';
import store from '../store/store';
import { baseURL, tokenKey } from './config';
import { isTokenExpired } from '../utils/auth.js'; // Import the utility function

// List of endpoints that can be requested multiple times (by default, request A cannot be initiated again before it is completed)
const unableCancelList = [];

// List of endpoints that do not trigger global loading
const disableLoadingList = [];

const instance = Axios.create({
  baseURL,
  timeout: 0,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
});

// Cancel request handler
const { CancelToken } = Axios;

// Request interceptor
instance.interceptors.request.use((config) => {
  const formDataType = 'application/x-www-form-urlencoded';
  if (config.headers['content-type'] === formDataType) {
    config.headers['content-type'] = formDataType;
    config.data = qs.stringify(config.data);
  }

  const token = store.getters.getToken;
  if (token && isTokenExpired(token)) {
    store.commit('removeToken');
    window.location.href = '/login';
    return Promise.reject(new Error('Token expired'));
  }

  if (token) {
    config.headers[tokenKey] = token;
  }

  const requestUrl = config.url;
  const queryStrIndex = requestUrl.indexOf('?') > -1 ? requestUrl.indexOf('?') : undefined;
  const cancelKey = requestUrl.substring(0, queryStrIndex);

  if (!disableLoadingList.includes(cancelKey) && !store.state.disableLoading) {
    store.commit('setLoading', true);
  }

  config.cancelToken = new CancelToken((cancel) => {
    const cancelTokenMap = store.getters.getCancelToken;
    if (cancelTokenMap[cancelKey] && !unableCancelList.includes(cancelKey)) {
      cancelTokenMap[cancelKey]();
    }

    const cancelItem = {
      key: cancelKey,
      value: cancel,
    };
    store.commit('setCancelToken', cancelItem);
  });

  return config;
}, (error) => {
  store.commit('setLoading', false);
  store.commit('setDisableLoading', false);
  return Promise.reject(error);
});

// Response interceptor
instance.interceptors.response.use((response) => {
  const configUrl = response.config.url;
  const queryStrIndex = configUrl.indexOf('?') > -1 ? configUrl.indexOf('?') : undefined;
  const cancelKey = configUrl.substring(0, queryStrIndex);
  const cancelTokenMap = store.getters.getCancelToken;

  if (cancelTokenMap[cancelKey]) {
    store.commit('deleteCancelToken', cancelKey);
  }

  if (Object.keys(cancelTokenMap).length === 0) {
    store.commit('setLoading', false);
  }
  store.commit('setDisableLoading', false);

  if (response.status === 204) {
    return null; // Return null or any appropriate value for 204 responses
  }

  if (response.status && response.status >= 200) {
    return response.data;
  }

  return response;
}, (error) => {
  if (error.toString() === 'Error: Request failed with status code 401') {
    store.commit('removeToken');
    window.location.reload();
    return Promise.reject(error);
  }

  store.commit('setLoading', false);
  console.error(error.toString());

  if (Axios.isCancel(error)) {
    return new Promise(() => {});
  }

  return Promise.reject(error);
});

// The second parameter of the get method request is also data
const iAxios = (method, url, data, config) => {
  return new Promise((resolve, reject) => {
    let requestConfig = null;
    let params = null;
    if (method.toLowerCase() === 'get') {
      params = data;
      data = null;
    }

    requestConfig = {
      method,
      url,
      data,
      params,
      ...config,
    };

    instance.request(requestConfig).then((response) => {
      if (response === null || typeof(response) === 'boolean' || typeof(response) === 'object' || response) {
        resolve(response);
      } else {
        reject(response);
      }
    }).catch((error) => {
      store.commit('setLoading', false);
      reject(error);
    });
  });
};

// Syntactic sugar for request methods
const get = (url, data, config) => iAxios('get', url, data, config);
const post = (url, data, config) => iAxios('post', url, data, config);
const put = (url, data, config) => iAxios('put', url, data, config);
const remove = (url, data, config) => iAxios('delete', url, data, config);
const native = (config) => instance.request(config);

export {
  get,
  post,
  put,
  native,
  remove
};
