import { h } from 'vue';
import lazyLoadComponent from '../utils/lazyLoadComponent';  // Make sure this is the correct path

const onboarding = [
  {
    path: '/demo',
    name: 'demo',
    component: {
      render() {
        return h('router-view');
      },
    },
    meta: {
      role: [0],
    },
    children: [
      {
        path: 'account-group',
        name: 'account-group',
        component: lazyLoadComponent('../views/demo/account-group.vue'),
        meta: { role: [0] },
      },
      {
        path: 'connect-platform',
        name: 'connect-platform',
        component: lazyLoadComponent('../views/demo/connect-platform.vue'),
        meta: { role: [0] },
      },
    ],
  },
];

export default onboarding;
