<template>
  <div class="login-box">
    <baseHeader></baseHeader>
    <div class="row ml-8 mr-0">
      <div class="content ml-8 mt-4 pl-0 text-left justify-content-start">
        <div class="pt-3">
          <h2 class="title-text">
            {{ $t('login.tipFragment1') }}<br/>
            {{ $t('login.tipFragment2') }}<br/>
            {{ $t('login.tipFragment3') }}
          </h2>
          <h4 class="mt-4 pt-3 small-text">
            {{ $t('login.tip2Fragment1') }}<br/>
            {{ $t('login.tip2Fragment2') }}
          </h4>
        </div>
        <div class="policy">
          <el-checkbox v-model="isAgree" label="" size="large" />
          <p class="policy-txt"><span @click="isAgree=!isAgree">{{ $t('login.tip3') }}</span> <router-link target="_blank" to="/protocol" >{{ $t('login.privacyPolicy') }}</router-link></p>
        </div>
        <div class="login-btn-box">
          <a
            @click="loginOauth('googleAnalytics')"
            class="pointer btn btn-social btn-google txt-social"
          >
            <!-- <i class="fa fa-google"></i> -->
            <img class="menu-logo" src="../../assets/btn_google_signin.png" alt />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseHeader from '../../components/onboarding/baseHeader.vue';
import {ElMessage} from "element-plus";

export default {
  components: {
    baseHeader,
  },
  data() {
    return {
      isAgree: false,
    }
  },
  methods: {
    loginOauth(provider) {
      if (!this.isAgree) {
        ElMessage.warning({
          message: 'Please agree to the user privacy policy',
        });
      } else {
        window.location.href = `/api/v2/auth/${provider}/user`;
      }
    },

    linkToCalendar() {
      window.location.href = 'https://www.calendly.com/su20yu1919/beta-testing-interests';
    },
  }
}
</script>

<style scoped>
.login-box {
  height: 100%;
  min-height: 768px;
  min-width: 1024px;
  padding-left: 120px;
  background-image: url("../../assets/onboarding/sign-in.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-color: white;
  background-size: auto 85%;
}

.title-text {
  font-family: Montserrat;
  font-size: 47.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e3655;
  margin-top: 90px;
}

.small-text {
  font-family: Montserrat;
  font-size: 23.9px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0e3655;
  margin: 40px 0;
}

.login-btn-box {
  /* width: 376px; */
  /* height: 40px; */
  /* border-radius: 16px; */
  /* background-color: #ec6161; */
  /* line-height: 40px; */
  text-align: left;
  
}
.menu-logo {
    width: 260px;
  }
.btn-google {

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.25px;
  text-align: center;
  color: #fff;
}
.policy {
  display: flex;
  align-items: center;
  padding-left: 2px;
  margin-bottom: 8px;
}

.policy a {
  color: var(--el-color-primary)
}

.policy-txt {
  margin-left: 8px;
}
</style>

