<template>
  <div class="callback">
    Shopify Store Name:
    <el-input v-model="shopifyName" placeholder="xxxx.myshopify.com"></el-input>
    <el-button @click="shopifyAccountSignIn">To bind</el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { shopifyBindAccount } from '@/api/user';
import { verifyShopoifyUrl } from '@/libs/common';

export default defineComponent({
  name: 'ShopifyBind',
  setup() {
    const route = useRoute();
    const shopifyName = ref('');
    const accountGroupId = route.query.accountGroupId;
    const shop = route.query.shop;

    const shopifyAccountSignIn = async () => {
      const verify = verifyShopoifyUrl(shopifyName.value);
      if (!verify) {
        ElMessage({
          message: 'Please enter the correct address',
          type: 'warning',
          duration: 5000
        });
        return null;
      }
      const result = await shopifyBindAccount({
        accountGroupId,
        shop: verify
      });
      console.log('result:', result);
      const href = `/api/v2/auth/shopify/account-group?accountGroupId=${accountGroupId}&shop=${verify}`;
      window.location.href = href;
    };

    onMounted(() => {
      if (shop) {
        shopifyName.value = shop;
        shopifyAccountSignIn();
      }
    });

    return {
      shopifyName,
      shopifyAccountSignIn
    };
  }
});
</script>

<style scoped>
.callback {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: #FFFFFF;
  font-size: 18px;
  background: #00b062;
  opacity: 0.7;
}
.tips-txt {
  font-size: 16px;
}
</style>
