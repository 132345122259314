import { get, post,remove } from '../libs/http';

const accountGroup = (params) => {
  return post('/api/v2/account-groups', params, {
    headers: {
      "content-type": "application/json"
    }
  });
}

const createAccountGroupCompanyConfig = (params) => {
  return post('/api-server/customer_settings/company_config', params);
}

const getPlatforms = (params) => {
  return get(`/api/v2/account-groups/${params}/platforms/`);
}

const getFaceBookAccounts = (params) => {
  return get('/api/v2/advertising-accounts/facebook', params, {
    headers: {
      "content-type": "application/json"
    }
  });
}

const postPlatform = (params) => {
  return post(`/api/v2/account-groups/${params.accountgroupId}/platforms/${params.platform}`, params, {
    headers: {
      'content-type': 'application/json'
    }
  });
}


const getFaceBookPagesAccounts = (params) => {
  return get('/api/v2/advertising-accounts/facebook-pages', params)
}


const getAdAccounts = (params) => {
  return get('/api/v2/advertising-accounts', params);
}

const getGoogleViews = (params) => {
  return get(`/api/v2/advertising-accounts/analytics/`, params, {
    headers: {
      "content-type": "application/json"
    }
  });
}
const getGoogleAdwords= (params) => {
  return get(`/api/v2/advertising-accounts/adwords`, params);
}

const removePlatform = (params) => {
  return remove(`/api/v2/account-groups/${params.accountGroupId}/platforms/${params.platform}`, params);
}


const signKlaviyo = (params) => {
  return post('/api-server/customer_settings/add_account_klaviyo_private_key', params);
}


export {
  accountGroup,
  getPlatforms,
  getFaceBookAccounts,
  getFaceBookPagesAccounts,
  getAdAccounts,
  removePlatform,
  getGoogleViews,
  getGoogleAdwords,
  postPlatform,
  createAccountGroupCompanyConfig,
  signKlaviyo
}
