<template>
  <div class="protocol">
    <div class="protocol-title">HUMANLYTICS PRIVACY POLICY</div>
    <div class="desc">As used in this “Privacy Policy”,“Humanlytics”refers to all websites, properties, and services owned or operated by Ruoyu Su, otherwise known as Humanlytics.</div>
    <div class="desc">This Privacy Policy describes the privacy practices of Humanlytics. It explains when   and how we collects end user and client information, including Personally Identifiable Information (defined below), how we use such information, and the circumstances     under which we may disclose such information to others. This Privacy Policy includes the policies that Humanlytics observes for compliance with laws in the United States as well as other applicable laws, including those followed regarding the transfer of     Personally Identifiable Information from the European Economic Area (“EEA”) and/or  Switzerland to the United States under the U.S. – EU Privacy Shield and U.S. – Swiss Safe Harbor, respectively (collectively, the “Safe Harbor and Privacy Shield”).</div>
    <div class="desc">By visiting the Humanlytics website, visiting a Humanlytics client website that provides notice of its use of Humanlytics and/or this policy, or registering for our service, you    acknowledge that you accept and consent to the practices outlined in this Privacy
      Policy.</div>
    <div class="title">What Types of User Information Does Humanlytics Obtain?</div>
    <div class="desc">Visitors to the Humanlytics Website. If you are a visitor to the Humanlytics website or a Humanlytics client (“Client”) who registers or signs into the Humanlytics website           (including for purposes of obtaining a free trial of Humanlytics’services), we may          obtain the following types of information from you or concerning your computer or       device (“Humanlytics Client Information”), which may include information that can itself be used to identify and/or contact you (“Personally Identifiable Information”):</div>
    <div class="desc">
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Website name and URL</li>
        <li>Credit card number</li>
        <li>Billing address</li>
        <li>Visitor site preferences</li>
      </ul>
    </div>
    <div class="desc">Humanlytics may also receive certain information that may be provided by your browser or mobile device, including:</div>
    <div class="desc">
      <ul>
        <li>Browser information</li>
        <li>Operating system information</li>
        <li>Mobile device information (e.g., device identifier, mobile operating system, etc.)</li>
        <li>IP address</li>
        <li>Pages accessed</li>
        <li>Time of visit</li>
        <li>Time of last visit</li>
        <li>Referring site, application, or service, including the relevant search queries that led you to Humanlytics’website</li>
      </ul>
    </div>
    <div class="desc">Humanlytics may use those data stated above for internal analytical and marketing purposes.</div>
    <div class="desc">We may also use those information to facilitate the delivery of content or                    advertisements that we believe may be of interest to you, or to communicate with you about new or existing products and services or special offers.</div>
    <div class="desc">Users of the Humanlytics Dataslinger Application. Dataslinger is a web application     owned and operated by Humanlytics with the functionality of compiling users’data   from platforms such as Google Analytics, Facebook Analytics and conduct advanced analyses on those data.</div>
    <div class="desc">In the application, users will be prompted to log-in with their accounts of the corresponding platforms, and Dataslinger (Humanlytics) will be able to gain access to the following information about your accounts:</div>
    <div class="desc">
      <ul>
        <li>All Google Analytics or Facebook Business accounts managed by that specific user</li>
        <li> All advertising data related to those accounts in question including but are not limited to the performance of various advertising campaigns</li>
      </ul>
    </div>
    <div class="desc">By default, all accounts the user have access to may be obtained for internal analytics and algorithm development purposes at Humanlytics. However, a user may at any time inform the Humanlytics team to exclude access of data to certain accounts.</div>
    <div class="desc">Humanlytics reserve the rights to use the data stated above for purposes including but are not limited to:</div>
    <div class="desc">
      <ul>
        <li>Improve our advertising and business objective recommendation system.</li>
        <li>Conduct internal research to inform feature development</li>
        <li>Selectively contact and alert users of anomalies in their advertising data.</li>
      </ul>
    </div>
    <div class="desc">However, Humanlytics will NOT:</div>
    <div class="desc">
      <ul>
        <li>Share data or information of an individual user with anyone outside of the Humanlytics team without the permission of the user</li>
        <li>Modify users’data without explicit permission of the users either via the dataslinger application or written consent.</li>
      </ul>
    </div>
    <div class="desc">Humanlytics Dataslinger use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</div>
    <div class="title">How to Contact Us</div>
    <div class="desc">Humanlytics welcomes any questions or comments about this Privacy Policy or our
      privacy practices. Any such comments or questions should be submitted via e-mail to: <a href="mailto:bill@humanlytics.co">bill@humanlytics.co</a></div>
    <div class="desc">This privacy policy will take in effect starting 11/1/2017, but Humanlytics reserve all rights to modify this privacy policy in the future.</div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import {onMounted, reactive} from "vue";


export default {
  name: 'callback',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();


    onMounted(() => {

    });

    return {
    }
  }
}
</script>

<style scoped>
.protocol {
  padding: 20px;
  font-size: 18px;
}

.protocol-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bolder;
}
.title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.desc {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  padding-left: 10px;
}
ul {
  padding-left: 20px;
}
ul li {
  padding-left: 10px;
  list-style-type: disc;
}
</style>
