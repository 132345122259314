import { createStore } from 'vuex'
import { getCookie, setCookie } from '../libs/utils';
import { cookieExpires, tokenKey } from '../libs/config';

export default createStore({
  state() {
    return {
      accountgroupID: localStorage.accountgroupID,
      auth: {
        facebook: {
          accessToken: ''
        },
        ga: {
          accessToken: '',
          refreshToken: ''
        },
        googleAds: {
          accessToken: '',
          refreshToken: ''
        },
      },
      userId: '',
      userDetail: null,
      campaignId: localStorage.campaignId,
      beforePath: localStorage.beforePath,
      taskId: null,
      isIntegrating: false,
      currentPage: 1,
      accountGroupStatus: '',
      pageConfig: {},
      // 注册
      register: {
        describe: 4, // 分析能力
        name: '', // 公司名称
        timezone: '', // 时区
        industry: '', // 公司行业
        role: '', // 职位名称
        useCase: '',
        employees: '',
        needs: [], //
      },
      overview: {
        config: null,
        data: null,
        pageCount: null,
        stateDate: []
      },
      settings: {
        config: null,
        services: null,
        revenueSourceOptions: null,
        cohortTypeOptions: null,
        contries: null,
        conversions: null,
        orderExclusions: null,
        lineItemFilter: null,
        productCategory: null
      },
      platforms: {
      },
      goalParams: {
        "metricName":'',
        "metricId":"",
        "startDate":"",
        "endDate":"",
        "goalType":"",
        "filters":[],
        "goalTarget":0,
        "platform":"",
        "accountGroupId":"",
        parentId: ''
      },
      goals: {
        title: 'Goals',
        total: 0,
      },
      reset: 0, // 0:不展示reset按钮 1:显示reset按钮 2:点击了reset按钮
      // 用户登录标识
      token: null,

      // 用户集成状态
      personalProfile: JSON.parse(getCookie('personalProfile') || '{"hasSeenIntro": false, "initialGroupConfigured": false}'),


      // 全局加载状态
      loading: false,

      // 是否禁用全局加载状态
      disableLoading: false,

      // 保存所有请求cancelToken的cancel方法
      cancelTokenMap: {},

      // 用户等级: General、Advanced、Professional
      // General: 一般客户可以查看report/goal版块
      // Advanced: 进阶客户可以查看report/goal/advance analysis # 20230320 暂时删除进阶客户
      // Professional: 高级客户可以查看report/goal/advance analysis/ action
      // ad-scoring 要话，也有2个版本，一个是一般客户；另一个是进阶客户和高级客户看的
      customerLevel: 'General',
      adAccounts: null,
      googleAnalyticsAccounts: null,
      reloadGa4: true
    }
  },
  mutations: {
    setGoogleAnalyticsAccounts(state, payload) {
      state.googleAnalyticsAccounts = payload;
    },
    commitAccountgroupID(state, accountgroupID) {
      localStorage.accountgroupID = accountgroupID;
      state.accountgroupID = accountgroupID;
    },
    commitCampaignId(state, campaignId) {
      localStorage.campaignId = campaignId;
      state.campaignId = campaignId;
    },
    commitBeforePath(state, beforePath) {
      localStorage.beforePath = beforePath;
      state.beforePath = beforePath;
    },
    commitTaskId(state, taskId) {
      state.taskId = taskId;
    },
    // playload: {key: 'describe', value:'2'}
    commitRegister(state, playload) {
      state.register[playload.key] = playload.value;
    },
    commitGoalsParams(state, playload) {
      state.goalParams[playload.key] = playload.value;
    },
    commitGoals(state, playload) {
      state.goals[playload.key] = playload.value;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setGa4Status(state, status) {
      state.reloadGa4 = status;
    },
    setPlatforms(state, platforms) {
      state.platforms = platforms;
    },
    setPlatformData(state, platform) {
      state.platforms[platform.name] = platform.value;
    },
    setPlatformEmail(state, platform) {
      state.platforms['email'][platform.name] = platform.value;
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    setPageConfig(state, pageConfig) {
      state.pageConfig = pageConfig;
    },
    // 设置token
    setToken(state, data) {
      const token = 'Bearer ' + data;
      state.token = token;
      setCookie(tokenKey, token, {
        expires: cookieExpires,
      });
    },
    setOverviewConfig(state, data) {
      state.overview.config = data;
    },
    setOverviewReportData(state, data) {
      state.overview.data = data;
    },
    setOverviewPageCount(state, data) {
      state.overview.pageCount = data;
    },
    setOverviewStartDate(state, data) {
      state.overview.stateDate = data;
    },
    setSttingsConfig(state, data) {
      state.settings.config = data;
    },
    setSttingsServices(state, data) {
      state.settings.services = data;
    },
    setSttingsRevenueSourceOptions(state, data) {
      state.settings.revenueSourceOptions = data;
    },
    setSttingsCohortTypeOptions(state, data) {
      state.settings.cohortTypeOptions = data;
    },
    setSttingsContries(state, data) {
      state.settings.contries = data;
    },
    setSttingsConversions(state, data) {
      state.settings.conversions = data;
    },
    setSttingsOrderExclusions(state, data) {
      state.settings.orderExclusions = data;
    },
    setSttingsLineItemFilter(state, data) {
      state.settings.lineItemFilter = data;
    },
    setSttingsProductCategory(state, data) {
      state.settings.productCategory = data;
    },
    removeToken(state) {
      state.token = '';
      setCookie(tokenKey, '');
    },

    // 设置用户集成状态
    setPersonalProfile(state, data) {
      state.personalProfile = data;
      setCookie('personalProfile', JSON.stringify(state.personalProfile), { expires: cookieExpires });
    },
    // 设置全局loading状态
    setLoading(state, status) {
      state.loading = status;
    },

    // 设置是否禁用全局loading状态
    setDisableLoading(state, status) {
      state.disableLoading = status;
    },

    // 设置getCancelTokenMap
    setCancelToken(state, cancel) {
      state.cancelTokenMap[cancel.key] = cancel.value;
    },

    // 移除cancelToken
    deleteCancelToken(state, key) {
      if (state.cancelTokenMap[key]) {
        delete state.cancelTokenMap[key];
      }
    },
    setKpi(state, payload) {
      state.kpi = payload;
    },
    setEfficiency(state, payload) {
      state.efficiency = payload;
    },
    setReset(state, payload) {
      state.reset = payload;
    },
    setCustomerLevel(state, payload) {
      state.customerLevel = payload;
    },
    setAccountGroupStatus(state, payload) {
      state.accountGroupStatus = payload;
    },
    setUserDetail(state, payload) {
      state.userDetail = payload;
    },
    setAdAccounts(state, payload) {
      state.adAccounts = payload;
    }
  },
  getters: {
    getAccountgroupID(state) {
      return state.accountgroupID;
    },
    getCampaignId(state) {
      return state.campaignId;
    },
    getBeforePath(state) {
      return state.beforePath;
    },
    getPageConfig(state) {
      return state.pageConfig
    },
    getTaskId(state) {
      return state.taskId;
    },
    getCurrentPage(state) {
      return state.currentPage
    },
    getRegister(state) {
      return state.register;
    },
    getUserId(state) {
      return state.userId;
    },
    getPlatforms(state) {
      return state.platforms;
    },
    getGA4Token(state) {
      return state.platforms.auth.ga.refreshToken;
    },
    getIsIntegrating(state) {
      return state.isIntegrating;
    },
    getGoalstotal(state) {
      return state.goals.total;
    },
    getGoalsTitle(state) {
      return state.goals.title;
    },
    // 获取用户登录标识
    getToken(state) {
      return state.token || getCookie(tokenKey);
    },
    getSttings(state) {
      return state.settings;
    },
    getGoalsParams(state) {
      return state.goalsParams;
    },
    getCancelToken(state) {
      return state.cancelTokenMap;
    },

    // 获取集成状态
    getPersonalProfile(state) {
      return state.personalProfile;
    },
    getCustomerLevel(state) {
      return state.customerLevel;
    },
    getAccountGroupStatus(state) {
      return state.accountGroupStatus;
    },
    getUserDetail(state) {
      return state.userDetail;
    }
  }
})
