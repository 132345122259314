import { get,put,post } from '../libs/http';

const getUserInfo = (params) => {
  return get('/api/v2/user/me', params);
}

const getTestUserToken = (params) => {
  return get('/api/v2/auth/local/user', params);
}

const shopifyQuickLogin = (params) => {
  return get('/api/v2/auth/local/user/shopify-quick-login', params);
}

const shopifyBindAccount = (params) => {
  return post('/api/v2/auth/shopify/account-group/bindShop', params);
}

const updateUserInfo = (params) => {
  return put('/api/v2/user/me', params, {
    headers: {
      'content-type': 'application/json'
    }
  });
}

const getUserLevel = (accountGroupID) => {
  return get(`/api-server/customer_settings/company_config?accountGroupId=${accountGroupID}`);
}

const getHealthValue = (accountGroupId) => {
  return get('/api-server/ad_scoring/get_health?accountGroupId=' + accountGroupId);
}
const getMenus = (customerLevel, accountGroupID) => {
  return get(`/api-server/customer_settings/get_menus?customerLevel=${customerLevel}&accountGroupId=${accountGroupID}`);
}
const updateMenus = (accountGroupId) => {
  return put('/api-server/customer_settings/update_menu?accountGroupId=' + accountGroupId);
}

const getCompany = (company) => {
  return get('/api-server/customer_settings/check_company_config?company=' + company);
}

export {
  getUserInfo,
  updateUserInfo,
  getTestUserToken,
  getUserLevel,
  shopifyQuickLogin,
  getHealthValue,
  getMenus,
  updateMenus,
  getCompany,
  shopifyBindAccount
}
