import { createRouter, createWebHistory } from 'vue-router';
import { findIndex } from 'lodash';
import store from '../store/store';
import { getTestUserToken, getUserLevel, getUserInfo } from '../api/user';

// Import route modules
import commonRoutes from './common';
import mainRoutes from './main';
import onboardingRoutes from './onboarding';
import onboardingDemoRoutes from './demo';
import dimensionRoutes from './dimension';
import conversionRoutes from './conversion';
import warningRoutes from './warning';
import kpiRoutes from './kpi';
import goalsRoutes from './goals';
import actionRoutes from './action';

// 常量定义
const WHITE_LIST_ROUTES = [
  'login',
  'callback',
  'shopify-login',
  'protocol',
  'demo',
  'account-group',
  'connect-platform',
  'shopify-bind',
  'shopify-bind-success',
];

const ROLE_REDIRECTS = {
  1: '/onboarding/step2-3',
  2: '/onboarding/step3-connect-platform',
  3: '/report/overview?page=1',
  default: '/login'
};

// 用户角色计算
const calculateUserRole = (hasSeenIntro, initialGroupConfigured) => {
  if (!hasSeenIntro) return 1;
  if (!initialGroupConfigured) return 2;
  return 3;
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: { template: '<div>Redirecting...</div>' },
    },
    ...commonRoutes,
    ...mainRoutes,
    ...onboardingRoutes,
    ...onboardingDemoRoutes,
    ...dimensionRoutes,
    ...kpiRoutes,
    ...goalsRoutes,
    ...actionRoutes,
    ...conversionRoutes,
    ...warningRoutes,
  ],
});

// 取消之前的请求
const cancelPreviousRequests = (store) => {
  const cancelTokenMap = store.getters.getCancelToken;
  Object.entries(cancelTokenMap || {}).forEach(([key, cancelFn]) => {
    cancelFn();
    store.commit('deleteCancelToken', key);
  });
};

router.beforeEach(async (to, from, next) => {
  try {
    // 取消之前的请求
    cancelPreviousRequests(store);

    const token = store.getters.getToken;
    const personalProfile = store.getters.getPersonalProfile;
    const accountGroupId = store.getters.getAccountgroupID;
    const accountGroupStatus = store.getters.getAccountGroupStatus;
    
    // 处理个人配置
    const { hasSeenIntro, initialGroupConfigured } = personalProfile;
    if (accountGroupStatus === 'process') {
      store.commit('setPersonalProfile', { 
        hasSeenIntro, 
        initialGroupConfigured: false 
      });
    }

    // 计算用户角色
    const role = calculateUserRole(hasSeenIntro, initialGroupConfigured);

    // 处理 Shopify 设置重定向
    if (to.path === '/settings/setting' && to.query.accountGroupId && to.query.shop) {
      if (!accountGroupId || accountGroupId !== to.query.accountGroupId) {
        return next({ 
          path: '/shopify-bind-success', 
          query: to.query 
        });
      }
    }

    // 处理根路径重定向
    if (to.fullPath === '/' && token) {
      await getUserInfo();
      return next('/report/overview?page=1');
    }

    // 处理测试用户令牌
    if (to.path === '/' && to.query.accountGroupId) {
      try {
        const tokenResult = await getTestUserToken({ 
          accountGroupId: to.query.accountGroupId 
        });
        
        const tempPersonalProfile = { 
          hasSeenIntro: true, 
          initialGroupConfigured: true 
        };

        if (to.query.addNew) {
          tempPersonalProfile.hasSeenIntro = to.query.addNew === '2';
          tempPersonalProfile.initialGroupConfigured = false;
        }

        // 更新 store
        store.commit('setPersonalProfile', tempPersonalProfile);
        store.commit('commitAccountgroupID', to.query.accountGroupId);
        store.commit('setToken', tokenResult.token);

        // 处理客户级别
        const customerLevel = to.query.level || 
          (await getUserLevel(to.query.accountGroupId)).customer_level;
        store.commit('setCustomerLevel', customerLevel);

        const newRole = calculateUserRole(
          tempPersonalProfile.hasSeenIntro, 
          tempPersonalProfile.initialGroupConfigured
        );

        if (to.query.authorizedPlatform && newRole === 3) {
          return next({ path: '/settings/setting' });
        }

        // 根据角色进行重定向
        return next(ROLE_REDIRECTS[newRole] || ROLE_REDIRECTS.default);
      } catch (error) {
        console.error('Failed to process test user token:', error);
        return next('/login');
      }
    }

    // 检查白名单路由
    if (to.path === '/' || (!token && !WHITE_LIST_ROUTES.includes(to.name))) {
      return next('/login');
    }

    // 角色验证
    if (!to.meta || findIndex(to.meta.role, o => o === 0) !== -1) {
      return next();
    }
    
    if (findIndex(to.meta.role, o => o === role) !== -1) {
      return next();
    }

    // 根据角色重定向
    return next(ROLE_REDIRECTS[role] || ROLE_REDIRECTS.default);
    
  } catch (error) {
    console.error('Navigation error:', error);
    store.commit('setToken', null);
    next('/login');
  }
});

export default router;
