<template>
  <div class="onboarding__header">
    <div class="onboarding__logo"></div>
  </div>
</template>

<script>
export default {
  name: 'baseHeader'
}
</script>

<style scoped>
.onboarding__header {
  padding-top: 62px;
}
.onboarding__logo {
  width: 300px;
  height: 40px;
  background: url("../../assets/logo.png") center center / contain no-repeat;
  background-size: 280px 28px;
}
</style>
