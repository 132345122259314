import _ from 'lodash';
import moment from 'moment';
const thousandthCharacter = (str)=>  {
 return str.toString().replace(/\d{1,3}(?=(\d{3})+\b)/g, '$&,')
}
const parseNumb = (val) => {
    if (val === 'nan') {
        return val
    }
    if (Number(val)) {
      if (val.toString().indexOf('.') > 0) {
        val = parseFloat(val).toFixed(2)
        val = thousandthCharacter(val)
      }
      val = thousandthCharacter(val)
    }
    return val
  }
const wordsSeparatedBySpaces = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}
const strToNumber = (val)=> {
    if (val.toString().indexOf('.') > 0) {
        val = parseFloat(val)
        val = parseFloat(val.toFixed(2))
        if (val.toString().indexOf('.') < 0) {
            val = val + 0.0001
        }
    } else {
        val = Number(val)
    }
    return val
}
const strFloatToFixed = (val, length) => {
    if (val.toString().indexOf('.') > 0) {
        const arr = val.toString().split('.')
        return arr[0] + '.' + arr[1].substr(0, length)
    } else {
        return val
    }
}
const thousandthCharacterOrderBy = (data, key, order) => {
    if (data.length === 0 ) {
        return data
    }
    const val = Number.isFinite(Number(data[0][key].replace(',','')))
    if (val) { // 是数字
        data = _.map(data, o => {
            o[key] = strToNumber(o[key].replace(',','')) // 取消千位符,转换为数值
            return o
        })
        data = _.orderBy(data, [key], [order]) // 数值排序
        data = _.map(data, o => {
            o[key] = thousandthCharacter(strFloatToFixed(o[key], 2)) // 添加千位符,保留两位小数
            return o
        })
    } else {
        if (moment(data[0][key]).isValid()) { // 如果是时间
            if (order === 'desc') {
                data.sort((a, b) => new Date(b[key]) - new Date(a[key]));
            } else {
                data.sort((a, b) => new Date(a[key]) - new Date(b[key]));
            }
        } else { // 默认字符排序
            data = _.orderBy(data, [key], [order])
        }
    }
    return data
}

const verifyShopoifyUrl = (url) => {
    url = url.trim()
    const urlPattern = /^(https:\/\/)?([a-zA-Z0-9-]+\.)*(myshopify)\.(com)(\/)?$/i;
    if (!urlPattern.test(url)) {
      return null
    } else {
      return url.replace('https://', '').replace('com/', 'com')
    }
  };
export {
    thousandthCharacter,
    parseNumb,
    wordsSeparatedBySpaces,
    thousandthCharacterOrderBy,
    strFloatToFixed,
    strToNumber,
    verifyShopoifyUrl
  }