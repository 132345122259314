<template>
  <div class="callback">
    <h6 class="tips-txt">Please wait for initialization, it will take about half a minute...</h6>
  </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {getUserInfo, getUserLevel} from '@/api/user.js';
import {find} from 'lodash';

export default {
  name: 'callback',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const {token, referer, platform} = route.query;
    console.log('QUERY', route.query);

    if (token) {
      store.commit('setToken', token);
    }

    const fetchUserInfo = () => {
      getUserInfo().then(async (res) => {
        let config = {};
        let status = '';
        if (res.activeAccountGroup) {
          config = await getUserLevel(res.activeAccountGroup);
          store.commit('setCustomerLevel', config.customer_level);
          store.commit('commitAccountgroupID', res.activeAccountGroup);
          const group = find(res.accountGroups, o => res.activeAccountGroup == o.accountGroupId);
          status = group.status;
          store.commit('setAccountGroupStatus', status);
        }

        const { personalProfile } = res;
        console.log(personalProfile);
        store.commit('setPersonalProfile', personalProfile);
        console.log('after', store.getters.getPersonalProfile);

        if (!personalProfile.hasSeenIntro) {
          router.replace('/onboarding/step2-3');
          return;
        } else if (personalProfile.hasSeenIntro && !personalProfile.initialGroupConfigured || status === 'process') {
          console.log('in process, or one not true')
          if (platform.includes('googleAdwords')) {
            router.push(`/onboarding/step3-platform-select-account/${res._id}/${res.activeAccountGroup}`);
          } else {
            console.log(store.getters.getPersonalProfile)
            router.replace('/onboarding/step3-connect-platform');
          }
          return;
        } else {
          console.log('both true')
          if (referer.includes('setting')) {
            if (platform.includes('googleAdwords')) {
              router.push(`/onboarding/step3-platform-select-account/${res._id}/${res.activeAccountGroup}?from=/settings/setting`);
            } else if (platform.includes('facebook')) {
              router.push(`/onboarding/step3-platform-facebook?from=/settings/setting`);
            } else {
              router.push('/settings/setting');
            }
          } else {
            console.log('going to overview')
            router.replace('/report/overview?page=1');
          }
          return;
        }
      });
    };

    fetchUserInfo();
  }
}
</script>


<style scoped>
.callback {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: #FFFFFF;
  font-size: 18px;
  background: #00b062;
  opacity: 0.7;
}
.tips-txt {
  font-size: 16px;
}
</style>
