import { h } from 'vue';

const onboarding = [
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('../views/onboarding/onboarding.vue'),
    meta: {
      role: [1], // 初始化权限
    },
    children: [
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import('../views/onboarding/welcome.vue'), // Assuming .vue extension
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'start',
        name: 'start',
        component: () => import('../views/onboarding/start.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step1-1',
        name: 'step1-1',
        component: () => import('../views/onboarding/step1-1.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step1-2',
        name: 'step1-2',
        component: () => import('../views/onboarding/step1-2.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step1-3',
        name: 'step1-3',
        component: () => import('../views/onboarding/step1-3.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step2-1',
        name: 'step2-1',
        component: () => import('../views/onboarding/step2-1.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step2-2',
        name: 'step2-2',
        component: () => import('../views/onboarding/step2-2.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step2-3',
        name: 'step2-3',
        component: () => import('../views/onboarding/step2-3.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step2-4',
        name: 'step2-4',
        component: () => import('../views/onboarding/step2-4.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step2-5',
        name: 'step2-5',
        component: () => import('../views/onboarding/step2-5.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step2-6',
        name: 'step2-6',
        component: () => import('../views/onboarding/step2-6.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step2-7',
        name: 'step2-7',
        component: () => import('../views/onboarding/step2-7.vue'),
        meta: {
          role: [1], // 初始化权限
        },
      },
      {
        path: 'step3-1',
        name: 'step3-1',
        component: () => import('../views/onboarding/step3-1.vue'),
        meta: {
          role: [1], // 账号集成权限
        },
      },
      {
        path: 'step3-connect-platform',
        name: 'step3-connect-platform',
        component: () => import('../views/onboarding/step3-connect-platform.vue'),
        meta: {
          role: [2], // 账号集成权限
        },
      },
      {
        path: 'step3-platform-properties/:userId/:groupId',
        name: 'step3-platform-properties',
        component: () => import('../views/onboarding/step3-platform-properties.vue'),
        meta: {
          role: [2, 3], // 账号集成权限
        },
      },
      {
        path: 'step3-platform-select-account/:userId/:groupId',
        name: 'step3-platform-select-account',
        component: () => import('../views/onboarding/step3-platform-select-account.vue'),
        meta: {
          role: [2, 3], // 账号集成权限
        },
      },
      {
        path: 'step3-platform-facebook-pages',
        name: 'step3-platform-facebook-pages',
        component: () => import('../views/onboarding/step3-platform-facebook-pages.vue'),
        meta: {
          role: [2, 3], // 账号集成权限
        },
      },
      {
        path: 'step3-platform-facebook',
        name: 'step3-platform-facebook',
        component: () => import('../views/onboarding/step3-platform-facebook.vue'),
        meta: {
          role: [2, 3], // 账号集成权限
        },
        props: true,
      },
      {
        path: 'step3-platform-instagram',
        name: 'step3-platform-instagram',
        component: () => import('../views/onboarding/step3-platform-instagram.vue'),
        meta: {
          role: [2, 3], // 账号集成权限
        },
      },
      {
        path: 'step3-2',
        name: 'step3-2',
        component: () => import('../views/onboarding/step3-2.vue'),
        meta: {
          role: [2], // 账号集成权限
        },
      },
    ]
  }
]

export default onboarding;
