import LoadingSpinner from '../components/LoadingSpinner.vue';
import ErrorComponent from '../components/ErrorComponent.vue';

const main = [
	{
		path: '/campaign',
		component: () => import('../pages/main.vue'),
		meta: { role: [3] },
		children: [
			{
				path: 'list',
				component: () => import('../components/campaignList.vue'),
				meta: { role: [3] },
			},
			{
				path: 'test',
				component: () => import('../components/test.vue'),
				meta: { role: [0] },
			},
			{
				path: 'createAd',
				component: () => import('../components/createAd.vue'),
				meta: { role: [3] },
			},
			{
				path: 'createAdSet',
				component: () => import('../components/createAdSet.vue'),
				meta: { role: [3] },
			},
			{
				path: 'semantics',
				component: () => import('../components/semantics.vue'),
				meta: { role: [3] },
			},
			{
				path: 'createCampaign',
				component: () => import('../components/createCampaign.vue'),
				meta: { role: [3] },
			},
			{
				path: 'switchCampaign',
				component: () => import('../components/switchCampaign.vue'),
				meta: { role: [3] },
			},
			{
				path: 'createFacebookSetting',
				component: () => import('../components/createFacebookSetting.vue'),
				meta: { role: [3] },
			},
			{
				path: 'existFacebookCampaign',
				component: () => import('../components/existFacebookCampaign.vue'),
				meta: { role: [3] },
			},
			{
				path: 'createVariations',
				component: () => import('../components/createVariations.vue'),
				meta: { role: [3] },
			},
			{
				path: 'taskPage',
				component: () => import('../components/taskPage.vue'),
				meta: { role: [3] },
			},
			{
				path: 'taskList',
				component: () => import('../components/taskList.vue'),
				meta: { role: [3] },
			},
			{
				path: 'taskRunList',
				component: () => import('../components/taskRunList.vue'),
				meta: { role: [3] },
			},
			{
				path: 'createSemantic',
				component: () => import('../components/createSemantic.vue'),
				meta: { role: [3] },
			},
			{
				path: 'finishPage',
				component: () => import('../components/finishPage.vue'),
				meta: { role: [3] },
			},
		],
	},
	{
		path: '/chart',
		component: () => import('../pages/main.vue'),
		children: [
			{
				path: 'chart',
				component: () => import('../components/chart.vue'),
				meta: { role: [3] },
			},
			{
				path: 'chart2',
				component: () => import('../components/chart2.vue'),
				meta: { role: [3] },
			}
		]
	},
	{
		path: '/settings',
		component: () => import('../pages/main.vue'),
		children: [
			{
				path: 'setting',
				component: () => import('../components/settings/setting.vue'),
				meta: { role: [3] },
			},
			{
				path: 'confirmPage',
				component: () => import('../components/settings/confirmPage.vue'),
				meta: { role: [3] },
			},
		]
	},
	{
		path: '/report',
		component: () => import('../pages/main.vue'),
		children: [
			{
				path: 'overview',
				component: () => import('../pages/overview.vue'),
				meta: { role: [3] },
			}
		]
	},
	{
		path: '/adaudit',
		component: () => import('../pages/main.vue'),
		children: [
			{
				path: 'report',
				component: () => import('../pages/adAudit.vue'),
				meta: { role: [3] },
			},
			{
				path: 'dailyReport',
				component: () => import('../pages/dailyReport.vue'),
				meta: { role: [3] },
			},
			{
				path: 'snowplow',
				component: () => import('../pages/snowplowByChannel.vue'),
				meta: { role: [3] },
			},
			{
				path: 'snowplowBehavior',
				component: () => import('../pages/snowplowByChannelBehavior.vue'),
				meta: { role: [3] },
			},
			{
				path: 'snowplowCampaign',
				component: () => import('../pages/snowplowByChannelCampaign.vue'),
				meta: { role: [3] },
			},
		]
	},
];

export default main;
