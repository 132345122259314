export default {
    common: {
      next: 'Next',
      back: 'Back',
      connect: 'CONNECT',
      cancel: 'CANCEL',
      confirm: 'Confirm'
    },
    main: {
        signOut: 'Sign out',
        addGroup: 'Add Group',
        switchGroup: 'Switch account group'
    },
    menu: {
      settings: 'Settings',
      overview: 'Overview',
      adAnalysis: 'Ad Analysis',
      action: 'Action',
      goal: 'Goal',
      advanceAnalysis: 'Advance Analysis',
      campaignAnalysis: 'Campaign Analysis',
      efficiencyAnalysis: 'Efficiency Analysis',
      dimensionTimeSeries: 'Dimension Time Series',
      KPISelection: 'KPI Selection',
      GAClickMatching: 'GA Click Matching',
      addGroup: 'Add Group',
      adScoring: 'Ad Scoring',
      dailyReport: 'Daily Report',
      reports:'Reports'
    },
    login: {
      tipFragment1: 'Welcome to the Data Slinger',
      tipFragment2: 'Digital Analytics Experience by',
      tipFragment3: 'Humanlytics.',
      tip2Fragment1: 'Please sign in below with the Google/Facebook account',
      tip2Fragment2: 'that is linked with your Google Analytics/Facebook Ads platforms',
      tip3: 'I agree with the',
      privacyPolicy: 'user privacy policy',
      SignButton: 'Sign in with Google'
    },
    step23: {
      companyName: 'Let’s begin by understanding a little more of your company/client. What is the name of your company/client?',
      timezone: 'what time zone do you want your company\'s sales or analysis platforms to unify?'
    },
    step31: {
      tip: 'Your Account Group is now created in our database. Now let\'s integrate all of your analytical platforms with this account group',
      tip2: 'Please select the analytical accounts you wish to analyze through our platforms (only Google Analytics will be avaliable currently for the beta)'
    },
    connect: {
      tip: 'Now let’s integrate all of your analytical platforms with this account group',
      tip2: 'Please select the analytical accounts you wish to analyze through our platforms (all three platforms recommended, if apply)',
      different: 'Sign in a different account',
      toConnect: 'To continue with your current',
      toConnect2: 'account, press continue if you wish to use another account please provide the authentication details.',
      login: 'Sign in',
      countinue: 'Continue',
      bindShare: 'Share bind link'
    },
    analysis: {
      name: 'Name',
      timezone: 'timezone',
      managerAccount: 'Manager Account',
      selected: 'Selected',
      created: 'AccountId',
      accounts: 'Accounts',
      properties: 'Properties',
      views: 'Views',
      balance: 'Recent Spend'
    }
  };
