<template>
  <div class="index">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { mapGetters,mapMutations } from 'vuex'
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["commitBeforePath"])
  },
  computed: {
  // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      'getAccountgroupID',
      "getCampaignId",
      "getBeforePath"
    ])
  },
  mounted() {

  }
};
</script>

<style lang="less">
</style>
