import lazyLoadComponent from '../utils/lazyLoadComponent';

const dimension = [
	{
		path: '/dimension',
		component: lazyLoadComponent('../pages/main.vue'),
		meta: { role: [3] },
		children: [
			{ path: 'efficiency', component: lazyLoadComponent('../views/dimension/efficiency.vue'), meta: { role: [3] } },
			{ path: 'timeSeries', component: lazyLoadComponent('../views/dimension/timeSeries.vue'), meta: { role: [3] } },
			{ path: 'timeSeriesFree', component: lazyLoadComponent('../views/dimension/timeSeriesForFree.vue'), meta: { role: [3] } },
			{ path: 'spendVSRevenueFree', component: lazyLoadComponent('../views/dimension/spendVSRevenueFree.vue'), meta: { role: [3] } },
			{ path: 'clickMatching', component: lazyLoadComponent('../views/dimension/clickMatching.vue'), meta: { role: [3] } },
			{ path: 'allSpendVsAllRevenueFree', component: lazyLoadComponent('../views/dimension/allSpendVsAllRevenueFree.vue'), meta: { role: [3] } },
			{ path: 'spendVsConversionFree', component: lazyLoadComponent('../views/dimension/spendVsConversionFree.vue'), meta: { role: [3] } },
		],
	},
];

export default dimension;
