// 请求路径
const baseURL = '';

// token名称
const tokenKey = 'Authorization';

const seconds = 60 * 60; // 1小时过期
const cookieExpires = new Date(new Date() * 1 + seconds * 1000);


export {
  baseURL,
  tokenKey,
  cookieExpires,
}