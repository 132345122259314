import lazyLoadComponent from '../utils/lazyLoadComponent';

const kpi = [
	{
		path: '/kpi',
		component: lazyLoadComponent('../pages/main.vue'),
		meta: {
			role: [3],
		},
		children: [
			{
				path: 'selection',
				component: lazyLoadComponent('../views/kpi/selection.vue'),
				meta: {
					role: [3],
				},
			},
		],
	},
];

export default kpi;
