const dimension = [
	{
		path: '/warning',
		component: () => import('../pages/main.vue'),
		children: [
			{
				path: 'lowConversionRate',
				component: () => import('../views/warningSystem/lowConversionRate.vue'),
				meta: {
					role: [3], // 访问权限要求
				},
			},
			{
				path: 'revenueChanges',
				component: () => import('../views/warningSystem/revenueChanges.vue'),
				meta: {
					role: [3], // 访问权限要求
				},
			},
			{
				path: 'spendChanges',
				component: () => import('../views/warningSystem/spendChanges.vue'),
				meta: {
					role: [3], // 访问权限要求
				},
			},
			{
				path: 'zeroRevenueCampaigns',
				component: () => import('../views/warningSystem/zeroRevenueCampaigns.vue'),
				meta: {
					role: [3], // 访问权限要求
				},
			},
		]
	},
];
export default dimension;
