const action = [
	{
		path: '/action',
		component: () => import('../pages/main.vue'),
		meta: { role: [3] },
		children: [
			{
				path: 'action',
				component: () => import('../views/action/action.vue'),
				meta: { role: [3] },
			},
		],
	},
];

export default action;