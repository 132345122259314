const dimension = [
	{
		path: '/conversion',
		component: () => import('../pages/main.vue'),
		meta: { role: [3] },
		children: [
			{
				path: 'conversionRate',
				component: () => import('../views/conversion/conversionRate.vue'),
				meta: { role: [3] },
			},
			{
				path: 'metricAnalysis',
				component: () => import('../views/conversion/metricAnalysis.vue'),
				meta: { role: [3] },
			},
			{
				path: 'non-AdAnalysis',
				component: () => import('../views/conversion/non-AdAnalysis.vue'),
				meta: { role: [3] },
			},
		],
	},
];

export default dimension;
