<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="filter-box">
      <el-form :inline="true" :model="form" ref="form" label-width="150px">
        <el-form-item label="Base Date" prop="startDate">
          <el-select v-model="form.currrent" placeholder="Select" size="large">
            <el-option v-for="item in userInfo.accountGroups" :key="item" :label="item.accountGroupId" :value="item.accountGroupId" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-button @click="initNewAccountGroup()">
        Init New AccountGroup
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getUserInfo, updateUserInfo } from '@/api/user';
import { ElMessage } from 'element-plus';
import { User } from '@/model/userInfo';

export default defineComponent({
  name: 'admin',
  setup() {
    const store = useStore();
    const form = reactive({ currrent: '' });
    const userInfo = ref<User>({
      personalProfile: {
        initialGroupConfigured: true,
        hasSeenIntro: true
      },
      accountGroups: []
    });
    const loading = computed(() => store.state.loading);

    const fetchUserInfo = async () => {
      const data = await getUserInfo();
      userInfo.value = data;
    };

    const initNewAccountGroup = () => {
      userInfo.value.personalProfile.initialGroupConfigured = false;
      userInfo.value.personalProfile.hasSeenIntro = false;
      updateUserInfo(userInfo.value).then(() => {
        ElMessage.success('Account initialization succeeded!');
      });
    };

    onMounted(() => {
      fetchUserInfo();
    });

    return {
      form,
      userInfo,
      loading,
      initNewAccountGroup
    };
  }
});
</script>

<style scoped>
/* Add your styles here */
</style>
