<template>
  <div class="error-container">
    <h2>Oops! Something went wrong.</h2>
    <p v-if="error.message">{{ error.message }}</p>
    <p v-else>An unexpected error occurred.</p>
    <button @click="retry">Retry</button>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Error,
      default: null
    },
    retry: {
      type: Function,
      required: true
    }
  }
};
</script>

<style scoped>
/* Add your custom error styles here */
.error-container {
  /* ... */
}
</style>
