import Login from '../views/login/login.vue';
import ShopifyLogin from '../views/login/shopify.vue';
import ShopifyBind from '../views/common/shopifyBind.vue';
import ShopifyBindSuccess from '../views/common/shopifyBindSuccess.vue';
import Admin from '../pages/admin.vue';
import Callback from '../views/common/callback.vue';
import Protocol from '../views/common/protocol.vue';

const common = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { role: [0] }, // No integration needed to access
  },
  {
    path: '/shopify-login',
    name: 'shopify-login',
    component: ShopifyLogin,
    meta: { role: [0] },
  },
  {
    path: '/shopify-bind',
    name: 'shopify-bind',
    component: ShopifyBind,
    meta: { role: [0] },
  },
  {
    path: '/shopify-bind-success',
    name: 'shopify-bind-success',
    component: ShopifyBindSuccess,
    meta: { role: [0] },
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: { role: [0] },
  },
  {
    path: '/callback',
    name: 'callback',
    component: Callback,
    meta: { isGuest: true, role: [0] },
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: Protocol,
    meta: { isGuest: true, role: [0] },
  },
];

export default common;
